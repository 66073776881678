import http from '../../execution-http'

export const state = {
    items: [],
    loading: false
}

export const mutations = {
    SET_LOADING(state, newValue) {
        state.loading = newValue
    },
    SET_ITEMS(state, newValue) {
        state.items = newValue
    }
}

export const getters = {
    items(state) {
        return state.items;
    },
    loading(state) {
        return state.loading;
    },
}

export const actions = {

   load({ commit }) {
        commit('SET_LOADING', true);
         return new Promise( (resolve, reject) => {
            commit('SET_LOADING', true);
            http.get('api/active-indices').then((response)=>{
                commit('SET_ITEMS', response.data);
                commit('SET_LOADING', false);
                resolve();
            }).catch((error) => {
                commit('SET_LOADING', false);
                reject(error);
            });
        });
    },
}
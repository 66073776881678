// const signalR = require("@microsoft/signalr");
// import Swal from "sweetalert2";

let connection = null;

export const state = {
   connectionId: null
}

export const mutations = {
    SET_CONNECTIONID(state, newValue) {
        state.connectionId = newValue;
    },
}

export const actions = {
    connect(/*{ dispatch, commit }*/) {
        return new Promise( (resolve) => {
            // console.log('execution signalr connect');
            // if(connection) {
            //     connection.stop();
            // }
    
            // if(localStorage.getItem('token')) {
            //     const uri = process.env.VUE_APP_BASE_EXECUTION_URL + "/notifications";
            //     console.log('execution signlaR trying to connect ' + uri)
            //     connection = new signalR.HubConnectionBuilder()
            //     .withUrl(uri, { accessTokenFactory: () => localStorage.getItem('token') })
            //       .withAutomaticReconnect()
            //       .build();
            
            //     connection.on("UserMessage", data => {
            //       console.log('UserMessage', data);
            //        Swal.fire({
            //         title: data.value,
            //         width: 600,
            //         padding: 100,
            //         confirmButtonColor: "#556ee6",
            //         background:
            //           "#fff url(//subtlepatterns2015.subtlepatterns.netdna-cdn.com/patterns/geometry.png)"
            //       });
            //     });

            //     connection.on("ActiveIndexNotification", data => {
            //         console.log('ActiveIndexNotification', data);
            //         dispatch('activeIndices/load', null, { root: true });
            //       });
                
                
            //     connection.start()
            //       .then(() => {
            //         console.log('execution signlaR connected!', connection.connection.connectionId)
            //         commit('SET_CONNECTIONID', connection.connection.connectionId);
            //     })
            //     .catch(console.error);

            //     connection.onreconnected((connectionId) => {
            //         console.log('execution signlaR onreconnected!', connectionId)
            //         commit('SET_CONNECTIONID', connectionId);
            //     });

            //     connection.onclose(async () => {
            //         console.log('execution signlaR onclose!', connectionId)
            //     });
              
            // }
            resolve();
       });
   },
   disconnect() {
        return new Promise( (resolve) => {
            if(connection) {
               connection.stop();
            }
            connection = null;
            resolve();
        });
    },
}

import http from '@/oauth-client'

export const state = {
    items: []
}

export const mutations = {
    SET_ITEMS(state, newValue) {
        state.items = newValue
    }
}

export const getters = {
    items(state) {
        return state.items;
    },
    count(state) {
        return state.items.length;
    },
    activeExists(state) {
        return state.items.find(x => x.status === 'Pending' || x.status === 'Handling');
    }
}

export const actions = {

   loadActualStatus({ commit }) {
         return new Promise( (resolve, reject) => {
            http.get('api/messages/get-actual-status').then((response)=>{
                commit('SET_ITEMS', response.data);
                resolve();
            }).catch((error) => {
            
                reject(error);
            });
        });
    },
}
import Vue from 'vue'
import App from './App.vue'
import BootstrapVue from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import Vuelidate from 'vuelidate'
import VueSweetalert2 from 'vue-sweetalert2';
import VueMask from 'v-mask'
import * as VueGoogleMaps from 'vue2-google-maps'
import VueYoutube from 'vue-youtube'
import vco from "v-click-outside"

import router from './router'
import store from '@/state/store'
import i18n from './i18n'

// import VueHighcharts from 'vue-highcharts'
// import Highcharts from 'highcharts'
// import loadStock from 'highcharts/modules/stock'
// import loadIndicatorsAll from 'highcharts/indicators/indicators-all'
// loadStock(Highcharts)
// loadIndicatorsAll(Highcharts)
// Vue.use(VueHighcharts, { Highcharts })

import Highcharts from "highcharts";
import Stock from "highcharts/modules/stock";
import HighchartsVue from "highcharts-vue";
import IndicatorsAll from 'highcharts/indicators/indicators-all'
import ExportingInit from 'highcharts/modules/exporting'
import ExportData from 'highcharts/modules/export-data'
import highchartsMoreInit from "highcharts/highcharts-more";
import solidGaugeInit from "highcharts/modules/solid-gauge";
import hcHeatmap from "highcharts/modules/heatmap";

hcHeatmap(Highcharts);
ExportingInit(Highcharts);
ExportData(Highcharts);
Stock(Highcharts);
IndicatorsAll(Highcharts);
highchartsMoreInit(Highcharts);
solidGaugeInit(Highcharts);
Vue.use(HighchartsVue);

import "@/assets/scss/app.scss";

import moment from 'moment'
import 'moment/min/locales'

moment.locale('en')

import VueAuthHref from 'vue-auth-href'

const options = {
  token: () => localStorage.getItem('access_token'), // Note that this MUST BE a function that returns the token.
  // other options here (full list of options described below)
}
Vue.use(VueAuthHref, options)

Vue.prototype.moment = moment
Vue.config.productionTip = false
Vue.use(VueYoutube)
Vue.use(BootstrapVue)
Vue.use(vco)
Vue.use(Vuelidate)
Vue.use(VueSweetalert2);
Vue.use(VueMask)
Vue.use(require('vue-chartist'))
Vue.use(require('vue-moment'));
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyAbvyBxmMbFhrzP9Z8moyYr6dCr-pzjhBE',
    libraries: 'places',
  },
  installComponents: true
})
Vue.component('apexchart', VueApexCharts)

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')

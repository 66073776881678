import store from '@/state/store'

export default [
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/pages/account/login'),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (localStorage.getItem('access_token')) {
                    // Redirect to the home page instead
                    next({ name: 'home' })
                } else {
                    // Continue to the login page
                    next()
                }
            },
        },
    },
    {
        path: '/logout',
        name: 'logout',
        meta: {
            authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {
                store.dispatch('auth/logOut')
                const authRequiredOnPreviousRoute = routeFrom.matched.some(
                    (route) => route.push('/login')
                )
                // Navigate back to previous page, or home as a fallback
                next(authRequiredOnPreviousRoute ? { name: 'home' } : { ...routeFrom })
            },
        },
    },
  
    {
        path: '/',
        name: 'home',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/indices/listv2')
    },
    {
        path: '/execution/active-indices',
        name: 'execution-active-indices',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/execution/active-indices')
    },
    {
        path: '/portfolio-questionnaire',
        name: 'portfolio-questionnaire',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/indices/portfolio-questionnaire')
    },
    {
        path: '/protfolio-solution-templates',
        name: 'protfolio-solution-templates',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/indices/solution-templates')
    },
    
    {
        path: '/performance-overview',
        name: 'performance-overview',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/dashboard/performance-overview')
    },
    {
        path: '/execution',
        name: 'execution',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/execution/index')
    },
    {
        path: '/execution/:id',
        name: 'execution-item',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/execution/item'),
        props: true
    },
    {
        path: '/demo-requests',
        name: 'demo-requests',
        meta: {
            role:['administrator'],
            authRequired: true,
        },
        component: () => import('../views/pages/admin/demo-requests')
    },
    {
        path: '/news-subscription',
        name: 'news-subscription',
        meta: {
            role:['administrator'],
            authRequired: true,
        },
        component: () => import('../views/pages/admin/news-subscription')
    },
    {
        path: '/visitor-messages',
        name: 'visitor-messages',
        meta: {
            role:['administrator'],
            authRequired: true,
        },
        component: () => import('../views/pages/admin/visitor-messages')
    },
    {
        path: '/fx-hedge',
        name: 'fx-hedge',
        meta: {
            role:['fx_hedge'],
            authRequired: true,
        },
        component: () => import('../views/pages/fx-hedge/index')
    },
    {
        path: '/fx-hedge/:target/:desc',
        name: 'fx-hedge-details',
        meta: {
            role:['fx_hedge'],
            authRequired: true,
        },
        component: () => import('../views/pages/fx-hedge/details'),
        props: true
    },
    {
        path: '/users',
        name: 'users',
        meta: {
            role:['administrator'],
            authRequired: true,
        },
        component: () => import('../views/pages/admin/users')
    },
    {
        path: '/audit',
        name: 'audit',
        meta: {
            role:['administrator'],
            authRequired: true,
        },
        component: () => import('../views/pages/admin/audit')
    },
    {
        path: '/stocks',
        name: 'stocks',
        meta: {
            role:['administrator'],
            authRequired: true,
        },
        component: () => import('../views/pages/instruments/stocks')
    },
    {
        path: '/options',
        name: 'options',
        meta: {
            role:['administrator'],
            authRequired: true,
        },
        component: () => import('../views/pages/instruments/options')
    },
    {
        path: '/futures',
        name: 'futures',
        meta: {
            role:['administrator'],
            authRequired: true,
        },
        component: () => import('../views/pages/instruments/futures')
    },
    {
        path: '/fx-rates',
        name: 'fx-rates',
        meta: {
            role:['administrator'],
            authRequired: true,
        },
        component: () => import('../views/pages/instruments/fx-rates')
    },
    {
        path: '/products',
        name: 'products',
        meta: {
            role:['administrator'],
            authRequired: true,
        },
        component: () => import('../views/pages/products/products')
    },
    {
        path: '/screening-history',
        name: 'screening-history',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/indices/screening-history')
    },
    {
        path: '/screening/:id',
        name: 'screening',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/indices/screening'),
        props: true
    },
    {
        path: '/indices',
        name: 'indices',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/indices/list')
    },
  
    {
        path: '/indices/:id/edit',
        name: 'index-edit',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/indices/edit'),
        props: route => Object.assign({}, route.query, route.params),
    },
    {
        path: '/indices/:id/view',
        name: 'index-view',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/indices/view'),
        props: true
    },
    {
        path: '/upload-index',
        name: 'upload-index',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/indices/upload'),
        props: true
    },
    {
        path: '/dictionaries',
        name: 'dictionaries',
        meta: {
            role:['administrator'],
            authRequired: true,
        },
        component: () => import('../views/pages/dictionaries')
    },
    {
        path: '/tutorial',
        name: 'tutorial',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/tutorial')
    }
]

const signalR = require("@microsoft/signalr");


let connection = null;

export const state = {
   connectionId: null
}

export const mutations = {
    SET_CONNECTIONID(state, newValue) {
        state.connectionId = newValue;
    },
}

export const actions = {
    connect({ dispatch, commit }) {
        return new Promise( (resolve) => {
            
            if(connection) {
                connection.stop();
            }
    
            if(localStorage.getItem('access_token')) {
                const uri = process.env.VUE_APP_BASE_URL + "/client-notifications";
                console.log('signlaR trying to connect ' + uri)
                connection = new signalR.HubConnectionBuilder()
                  .withUrl(uri, { accessTokenFactory: () => localStorage.getItem('access_token') })
                  .withAutomaticReconnect()
                  .build();
            
                connection.on("RefreshIndex", data => {
                  console.log('RefreshIndex', data);
                });
                
                connection.on("RefreshMessageStatus", data => {
                    console.log('RefreshMessageStatus', data);
                    dispatch('messages/loadActualStatus', null, { root: true });
                });

                connection.on("RefreshMessageStatus", data => {
                    console.log('RefreshMessageStatus', data);
                    dispatch('messages/loadActualStatus', null, { root: true });
                });

                connection.on("UpdatePersons", data => {
                    console.log('UpdatePersons', data);
                    dispatch('contacts/reload', null, { root: true });
                    });

                connection.on("NewExecutionResponse", data => {
                    console.log('NewExecutionResponse', data);
                    commit('execution/END_REQUEST', { resp: data}, { root: true });
                });

                connection.on("UpdateBackendStatus", data => {
                    console.log('UpdateBackendStatus', data);
                    commit('execution/SET_IS_BACKEND_ALIVE', data, { root: true });
                });
                
                connection.start()
                  .then(() => {
                    console.log('signlaR connected!', connection.connection.connectionId)
                    commit('SET_CONNECTIONID', connection.connection.connectionId);
                    //dispatch('execution/isBackendAlive', null, {root: true});
                })
                .catch((e) =>{
                    commit('execution/SET_IS_BACKEND_ALIVE', false, { root: true });
                    console.error(e);
                });
                
                connection.onreconnecting((connectionId) => {
                    console.log('signlaR onreconnecting!', connectionId)
                    commit('execution/SET_IS_BACKEND_ALIVE', false, { root: true });
                });

                connection.onreconnected((connectionId) => {
                    console.log('signlaR onreconnected!', connectionId)
                    commit('SET_CONNECTIONID', connectionId);
                   // dispatch('execution/isBackendAlive', null, {root: true});
                });

                connection.onclose(async () => {
                   console.log('signlaR onclose');
                   commit('execution/SET_IS_BACKEND_ALIVE', false, { root: true });
                });
              
            }
            resolve();
       });
   },
   disconnect() {
        return new Promise( (resolve) => {
            if(connection) {
               connection.stop();
            }
            connection = null;
            resolve();
        });
    },
}
